.footer-container{
    background-color: black;
    width: 100%;
    /* height: 20vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
    margin-top: 5rem;
}

.footer-container h1{
    color: white;
    font-size: 1.5rem;
}
.footer-container p{
    color: #6E6D7A;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.footer-container p img{
    width: 1rem;
}
#number{
    color: white;
}
