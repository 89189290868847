.accomodation-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    /* height: 100vh; */
    /* width: 100vw; */
    gap: 5rem;
    /* margin-top: 2rem; */
    padding: 7rem 3rem;
}

.accomodation-container h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 4.8rem;
    color: #3F424A;

}

.image-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10rem;
    padding: 0 10rem;
}

.facility-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.facility-container img {
    width: 10rem;
}

.facility-container p {
    font-weight: 400;
    font-size: 1.0rem;
    line-height: 2.4rem;
    text-align: center;

    color: #6E6D7A;
    /* width: 10.9rem; */
}

html{
    font-size: 62.5%;
  }
  @media (max-width:998px) {
    html{
      font-size: 55%;
    }
  }
  
  @media (max-width:768px) {
    html{
      font-size: 45%;
      
    }
    .image-text-container {
        padding: 0 6rem;
    }
  }