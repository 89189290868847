.pricing-page-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    gap: 5rem;
    padding: 5rem 1rem;
    flex-wrap: wrap;
    scroll-behavior: smooth;
    height: 100%;
}

.room-container{
    height: 100%;
    padding: 3rem;
    /* width: 10rem; */
    background-color: #F1F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 2rem;
    gap: 2rem;
    max-width: 30rem;
}

.bed-image-container{
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    border-radius: 50%;


}
.bed-image-container img{
    width: 8rem;
}
.pricing-parent-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.room-container h3{
    color: #4D61FC;
}
.room-container p{
    color: #6E6D7A;
}



