html {
    font-size: 62.5%;
}

.container {
    margin: auto;
    padding: 1rem 4rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

#signin {
    background-color: white;
    color: black;
    border: .1rem solid black;
    /* border-style: none; */
    padding: 0.1rem 2.5rem;
    height: 3.5rem;
    border-radius: 2rem;
    transition: 300ms linear;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
#signin img{
    width: 1.75rem;
}

#signin:hover {
    background-color: black;
    color: white;
    transition: 300ms linear;
}

.logo img {
    height: 4rem;
}


.navbar {
    height: 6.15rem;
    background-color: white;
    position: relative;
    justify-content: center;
    align-items: center;
    box-shadow: none;

}


.menu-icon {
    display: none;
}

.nav-elements {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    gap: 6.0rem;
    align-items: center;

}

.nav-elements ul li {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;

}

.nav-elements ul li:not(:last-child) {
    margin-right: 6.0rem;
}

.nav-elements ul a {
    font-size: 1.2rem;
    font-weight: 400;
    color: black;
    text-decoration: none;
}

.nav-elements ul a.active {
    color: black;
    font-weight: 500;
    position: relative;
}



@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 3.0rem;
    }
}

@media (max-width: 600px) {
    .menu-icon {
        margin-top: 4px;
        display: block;
        cursor: pointer;
    }
    .menu-icon img{
        width: 2.5rem;
    }

    .container {
        padding: 3rem;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 6.0rem;
        bottom: 0;
        background-color: white;
        width: 0px;
        height: calc(100vh - 6.0rem);
        transition: all 0.3s ease-in;
        overflow: hidden;
    }

    .nav-elements ul a {
        text-align: center;
        font-size: 2.1rem;
    }


    .nav-elements.active {
        width: 35.0rem;

    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .nav-elements ul :not(:last-child) {
        padding-left: 2rem !important;
    }

    .nav-elements ul li {
        /* margin-right: unset; */
        font-size: 1.7rem;
        margin-top: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #signin {
        width: 20rem;
        height: 5rem;
        border-radius: 10rem;
    }
}

html{
    font-size: 62.5%;
  }
  @media (max-width:998px) {
    html{
      font-size: 55%;
    }
  }
  
  @media (max-width:768px) {
    html{
      font-size: 45%;
    }
  }

  body.disable-scroll {
    overflow: hidden;
  }