@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

}
html{
  font-size: 62.5%;
}
@media (max-width:998px) {
  html{
    font-size: 55%;
  }
}

@media (max-width:768px) {
  html{
    font-size: 45%;
  }


  
}


/* Disable text selection */
body {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

/* Disable element dragging */
img,
video {
  -webkit-user-drag: none; /* Safari */
  -webkit-user-drag: none;
}

/* Disable right-click context menu */
body {
  -webkit-touch-callout: none; /* Safari */
  -webkit-context-menu: none; /* Safari */
  -ms-touch-action: none; /* Internet Explorer/Edge */
}

/* Disable image dragging */
img {
  pointer-events: none;
}
