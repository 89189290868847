.herosection-container {
    display: flex;
    justify-content: center;
    gap: 10rem;
    align-items: center;
    flex-wrap:wrap-reverse;
    width: 100vw;
    /* height: 80vh; */
    padding: 4rem 3rem;
}

.text-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
}

.img-container img{
  width: 35rem;
}

.text-container h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 4.8rem;

    color: #3F424A;

}

.text-container p {
    width: 38.9rem;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 178%;
    color: #6E6D7A;

}

.text-container button {
    background: #4D61FC;
    border-radius: 3.0rem;
    /* height: 43px; */
    border-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 1.0rem 2.0rem;
    gap: .85rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.2rem;
    /* identical to box height */
    color: #FFFFFF;
    border: .1rem solid #4D61FC;
    transition: 300ms linear;
}



.text-container button:hover {
    background-color: black;
    color: white;
    border: .1rem solid black;
    transition: 300ms linear;

}

.text-container button img {
    width: 1.5rem;
}

.arrow-img-container img {
    width: 1.5rem;
}

html{
    font-size: 62.5%;
  }
  @media (max-width:998px) {
    html{
      font-size: 55%;
    }
  }
  
  @media (max-width:768px) {
    html{
      font-size: 45%;
    }
    .herosection-container{
      gap: 5rem;
    }
  }
