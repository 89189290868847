.contactus-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    /* padding: 7rem 4rem; */
    gap: 5rem;
    flex-wrap: wrap;

}

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 3rem;
    width: 50%;
}

.form-container h1 {
    font-size: 3.2rem;
    line-height: 4.8rem;
    color: #3F424A;
    font-weight: 600;
    text-align:left;
}

.form-container p {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 178%;
    color: #6E6D7A;
    text-align: left;
}

.maps-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    /* width: 50vw; */
}
.maps-container iframe{
    width: 100%;
}

.form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 2rem;
    gap: 2rem;
}

.row1-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    width: 100%;
    flex-wrap: wrap;
}

.input-container-row1{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    /* width: 50%; */
    width: 100%;
}
.input-container-row1 input{
    height: 3.5rem;
    /* width: ; */
    padding: 1rem;
    border-radius: .5rem;
    /* border-style:solid; */
    border: 1.5px solid #6E6D7A;
    width: 100%;
}
.input-container-row1 p{
    color: #3F424A;
    font-weight: 600;

}

.input-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.input-container input:focus{
    border: 1.5px solid black !important;
}

.input-container p{
    color: #3F424A;
    font-weight: 600;

}
.input-container input{
    height: 3.5rem;
    /* width: ; */
    padding: 1rem;
    border-radius: .5rem;
    width: 100%;
    /* border-style:solid; */
    border: 1.5px solid #6E6D7A;
}
.input-container textarea{
    padding: 1rem;
    border-radius: .5rem;
    border: 1.5px solid #6E6D7A;
    width: 100%;
}

.form button{
    width: 100%;
    height: 4.5rem;
    background-color: #4D61FC;
    color: white;
    border-radius: .5rem;
    border-color: white;
}

.maps-container{

}

html{
    font-size: 62.5%;
  }
  @media (max-width:998px) {
    html{
      font-size: 55%;
    }
  }
  
  @media (max-width:768px) {
    html{
      font-size: 45%;
    }
    .maps-container{
        width: 100%;
    }
    .maps-container iframe{
        height: 300;
    }
    .form-container{
        width: 100%;
    }
  }
